<template>
    <div class="page-table haberTanim mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.bultenler.guncelleme.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")'
                        :open-delay="500"
                        placement="top">
                    <el-button
                        v-on:click="refreshPage()"
                        size="mini"
                        type="text"
                        icon="el-icon-refresh">
                    </el-button>
                </el-tooltip>
            </div>

        </div>
        <el-row :gutter="10" v-loading="loading || detailLoading"
            :element-loading-text='loading ? $t("src.views.apps.bultenler.guncelleme.updateLoading") : $t("src.views.apps.bultenler.guncelleme.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="bultenForm" :rules="rulesBulten" ref="bultenForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.baslik")' prop="baslik">:
                                    <el-input size="small" v-model="bultenForm.baslik" :placeholder='$t("src.views.apps.bultenler.tanimlama.baslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.altbaslik")' prop="altBaslik">:
                                    <el-input size="small" v-model="bultenForm.altBaslik" :placeholder='$t("src.views.apps.bultenler.tanimlama.altBaslikPlace")'></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.kategoriler")' prop="kategori" :key="key1">:
                                        <el-select v-model="bultenForm.kategori" style="width: 100%" size="mini">
                                            <div v-loading="catLoading">
                                                <el-option v-for="item in kategoriListe" :key="item.kategoriID" :label="item.baslik" :value="item.kategoriID"></el-option>
                                            </div>
                                        </el-select>

                                        <!-- <el-input size="small"
                                            v-model="bultenForm.kategori"
                                            :placeholder='$t("src.views.apps.bultenler.tanimlama.altbaslikPlace")'></el-input> -->
                                    </el-form-item>
                                </el-col>
                        </el-row>

                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.icerik")' prop="icerik">:
                                    <div>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="bultenForm.icerik"></ckeditor>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                <el-form-item>
                                    <el-checkbox v-model="videoVis">{{$t("src.views.apps.bultenler.guncelleme.videoOlsunMu")}}</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                    <el-row :gutter="10">
                        <el-col v-if="videoVis" style="float: right" :lg="12" :md="12" :sm="24" :xs="24">
                            <el-collapse-item class=" animated fadeInDown card-shadow--medium" title='Video' name="2">
                                <el-row>
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                        <el-form-item :label='$t("src.views.apps.bultenler.guncelleme.video")' prop='video'>:
                                            <el-input size="small"
                                                type="textarea"
                                                :rows="5"
                                                v-model="bultenForm.video"
                                                :placeholder='$t("src.views.apps.bultenler.guncelleme.videoURLGir")'></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>
                        </el-col>
                    </el-row>
                    </el-collapse>

                    <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.bultenler.guncelleme.resimAyar")' name="4">
                            <Resim functionName="bultenGuncelleImage" :imageList="imageList" :link="bultenForm.link" :selectedsFromServer="selectedImagesFromServer"></Resim>
                        </el-collapse-item>
                        <!-- <el-collapse-item class="card-shadow--medium" title="Belge Ayarları" name="5">
                            <Belge functionName="bultenGuncelleBelge" :belgeList="belgeList" :link="bultenForm.link" :selectedsFromServer="selectedFilesFromServer"></Belge>
                        </el-collapse-item> -->
                    </el-collapse>

                    <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                        <draggable data-source="juju" class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.bultenler.guncelleme.seoAyar")' name="3">
                                <seo :modulName="'haber'" :baslik="bultenForm.baslik" :icerik="bultenForm.icerik" v-on:changeSeo="handleSeo" :seoBaslikF="bultenForm.seoBaslik" :seoIcerikF="bultenForm.seoIcerik"></seo>
                            </el-collapse-item>
                        </draggable>
                    </el-collapse>
                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp delay-1s" v-model="activeCollapse">
                        <el-collapse-item name="6" :title='$t("src.views.apps.bultenler.tanimlama.durum")'>
                            <el-row>
                                <el-button
                                    style="margin-bottom: 10px"
                                    size="mini" v-on:click="bultenGuncelle('bultenForm')"
                                    class="onayBtn"
                                    type="primary">
                                    {{$t('src.views.apps.bultenler.guncelleme.bultenGuncelle')}}
                                </el-button>
                            </el-row>
                        </el-collapse-item>

                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.bultenler.tanimlama.tarih")' name="9">
                                <el-row :gutter="5">
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                        <el-row>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-form-item style="position: relative;top: -25px" :label='$t("src.views.apps.bultenler.tanimlama.baslamaTarihi")' prop="tarih">
                                                    <el-date-picker size="small" class="select-wide" type="datetime"
                                                        :placeholder='$t("src.views.apps.bultenler.tanimlama.tarihPlace")'
                                                        :picker-options="dateRangeOptions"
                                                        v-model="bultenForm.baslamaTarihi"
                                                        format="yyyy-MM-dd HH:mm:ss"
                                                        value-format="yyyy-MM-dd HH:mm:ss">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-checkbox v-if="!checkedTarih" style="margin-bottom: 15px" v-model="checkedTarih">
                                                    {{$t("src.views.apps.bultenler.tanimlama.bitisTarihi")}}
                                                </el-checkbox>
                                                <el-col v-if="checkedTarih" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                    <el-form-item style="position: relative;top: -20px" :label='$t("src.views.apps.bultenler.tanimlama.bitisTarihi")' prop="bitisTarihi">:
                                                        <el-date-picker size="small" class="select-wide" type="datetime"
                                                            :placeholder='$t("src.views.apps.bultenler.tanimlama.bitisTarihiPlace")'
                                                            :picker-options="dateRangeOptions"
                                                            v-model="bultenForm.bitisTarihi"
                                                            format="yyyy-MM-dd HH:mm:ss"
                                                            value-format="yyyy-MM-dd HH:mm:ss">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <!-- <konumlar :modulName="'haber'" :resim="bultenForm.resimKonum" :menu="bultenForm.menuKonum" :galeri="bultenForm.galeriKonum" v-on:changeKonum="handleKonum"></konumlar> -->
                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import JQuery from 'jquery';
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import Belge from '@/components/Belge.vue'
    import Seo from '@/components/Seo.vue'
    import Konumlar from '@/components/Konumlar.vue'

    let $ = JQuery;
    import bultenService from '../../../WSProvider/BultenService'
    import bultenKategoriService from '../../../WSProvider/BultenKategoriService'
    import notification from '../../../notification'
    import functions from '../../../functions'

    var moment = require('moment');

    export default {
        name: "HaberlerDuzenle",
        components: {
            draggable,
            Resim,
            Belge,
            Seo,
            Konumlar
        },
        computed: {
            imageListMain(){
                return this.bultenForm.resim
            },

            haberStoreData(){
                return this.$store.getters.getBultenData;
            }
        },
        watch: {
            imageListMain: function (val) {
                // if (val.length === 1) {
                //     this.bultenForm.resimKonum = "1";
                //     this.bultenForm.galeriKonum = "0";
                // } else if (val.length > 1) {
                //     this.bultenForm.galeriKonum = "1";
                // } else {
                //     this.bultenForm.resimKonum = "0";
                //     this.bultenForm.galeriKonum = "0";
                // }
            },
            checkedTarih: function (val) {
                if (val === false) {
                    this.bultenForm.bitisTarihi = "";
                }
            },
            haberStoreData(val){
                this.updateBultenData = this.$store.getters.getBultenData
                this.getBultenDetay(this.updateBultenData.bultenID, this.updateBultenData.link)
            }
        },
        mounted() {
            this.updateBultenData = this.$store.getters.getBultenData
            this.getKategoriCount()

            var self = this;
            EventBus.$on("bultenGuncelleImage", function(bool, imageArray) {
                if (bool) {
                    self.bultenForm.resim = imageArray;
                }
            });


            EventBus.$on("bultenGuncelleBelge", function(bool, belgeArray) {
                if (bool) {
                    self.bultenForm.belge = belgeArray;
                }
            });
        },
        data() {
            return {
                key1: 0,
                totalKategori: 0,
                loading: false,
                detailLoading : false,
                catLoading: false,
                imageList:[],
                belgeList: [],
                selectedImagesFromServer: [],
                selectedFilesFromServer: [],
                path: bultenService.path,
                imagePath: bultenService.imagePath,
                belgePath: bultenService.belgePath,

                videoVis: false,

                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8','9', '10'],
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },

                checkedTarih: '',
                kategoriListe: [],
                updateBultenData: '',
                bultenForm: {
                    kategori:"",
                    bultenID: "",
                    kategoriBaslik: "",
                    baslik: '',
                    link: '',
                    video: "",
                    buton: "",
                    butonLink: "",
                    altBaslik: '',
                    icerik: '',
                    resim: [],
                    belge: [],
                    belgeKategori: "",
                    resimKonum: '',
                    menuKonum: '',
                    galeriKonum: '',
                    kaynakca: '',
                    webSite: [],
                    baslamaTarihi: '',
                    bitisTarihi: "",
                    durum: '',
                    sayac: '',
                    begen: '',
                    seoBaslik: '',
                    seoIcerik: '',
                },
                dateRangeOptions: {
                    firstDayOfWeek: 1
                },
                rulesBulten: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Bülten Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    kategori: [{
                        required: true,
                        message: "Lütfen Kategori Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                    seoBaslik: [{
                        max: 70,
                        message: "Tavsiye edilen SEO Başlık limitini aştınız.",
                        trigger: 'change'
                    }],
                    seoIcerik: [{
                        max: 160,
                        message: "Tavsiye edilen SEO İçerik limitini aştınız.",
                        trigger: 'change'
                    }],
                    
                },
            }
        },
        methods: {
            refreshPage(){
                this.getBultenDetay(this.updateBultenData.bultenID, this.updateBultenData.link)
            },
            handleSeo(seoBaslik,seoIcerik) {
                this.bultenForm.seoBaslik = seoBaslik;
                this.bultenForm.seoIcerik = seoIcerik;
            },
            handleKonum(resimKonum,menuKonum,galeriKonum) {
                this.bultenForm.resimKonum = resimKonum;
                this.bultenForm.menuKonum = menuKonum;
                this.bultenForm.galeriKonum = galeriKonum;
            },
            getKategoriListe() {
                try{
                    this.catLoading = true;
                    bultenKategoriService.bKategoriListe(1, 0 , this.totalKategori).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token)
                            this.kategoriListe = response.data;
                            this.bultenForm.kategori = response.data.length > 0 ? response.data[0].bkategoriID : '0'
                        }
                        this.catLoading = false;
                        this.key1++;
                        this.getBultenDetay(this.updateBultenData.bultenID, this.updateBultenData.link)
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){  
                                this.kategoriListe = [];
                                this.bultenForm.kategori = "";
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                        this.catLoading = false;
                    })
                }catch(e){
                    this.catLoading = false;
                }
            },
            getKategoriCount() {
                try{
                    this.detailLoading = true;
                    bultenKategoriService.bKategoriSayisi(1).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token);
                            this.totalKategori = response.data;
                            this.getKategoriListe();
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){  
                                this.totalKategori = 0
                            }
                        }else{
                            notification.Status(503, this)
                        }
                        this.detailLoading = false;
                    })
                }catch(e){
                    this.detailLoading = false;
                }
            },
            getBultenDetay(bultenID, link) {
                try{
                    this.detailLoading = true;
                    bultenService.bultenDetay(bultenID,link).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);

                            if (response.resimler) {
                                response.resimler.forEach(element => {
                                    if(element.resim !== ""){
                                        var item = {
                                            tmp_name: element.resim,
                                            name: element.resim,
                                            url: this.imagePath + element.resim,
                                        }
                                        this.imageList.push(item);
                                    }
                                })
                            }
                            this.bultenForm.bultenID = response.data.bultenID
                            this.bultenForm.kategori = response.data.kategoriID;
                            this.key1++;
                            this.bultenForm.baslik = response.data.baslik;
                            this.bultenForm.link = response.data.link;
                            this.bultenForm.altBaslik = response.data.altBaslik;
                            this.bultenForm.icerik = response.data.icerik ? response.data.icerik : "";
                            
                            if (response.data.video) {
                                this.videoVis = true;
                                this.bultenForm.video = (response.data.video);
                            }

                            if(response.data.basTarih){
                                this.bultenForm.baslamaTarihi = moment(response.data.basTarih).format('YYYY-MM-DD hh:mm:ss');
                            }

                            if(response.data.bitisTarih){
                                this.bultenForm.bitisTarihi = moment(response.data.bitisTarih).format('YYYY-MM-DD hh:mm:ss');
                            }
                            if (response.data.buton || response.data.butonLink) {
                                this.bultenForm.buton = response.data.buton;
                                this.bultenForm.butonLink = response.data.butonLink;
                            }
                            this.bultenForm.seoBaslik = response.data.seo_baslik;
                            this.bultenForm.seoIcerik = response.data.seo_icerik;
                            this.bultenForm.resimKonum = response.data.resimKonum
                            this.bultenForm.menuKonum = response.data.menuKonum
                            this.bultenForm.galeriKonum = response.data.galeriKonum
                            this.bultenForm.durum = response.data.durum
                        }
                        this.bultenForm = functions.removeSlashesAndDecodeStrings(this.bultenForm)
                        this.detailLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                        this.detailLoading = false;
                    })
                }catch(e){
                    this.detailLoading = false;
                }       
            },
            
            bultenGuncelle(formName) {
                if(this.validateForm(formName)){
                    try{
                        this.loading = true;
                        var resimUzunluk = this.bultenForm.resim.length;
                        var belgeUzunluk = this.bultenForm.belge.length;
                        var sunucudanEklenenResimler = this.selectedImagesFromServer.join(",");

                        var baslamaTarih = this.bultenForm.baslamaTarihi ? moment(this.bultenForm.baslamaTarihi).format('YYYY-MM-DD hh:mm:ss') : ''
                        var bitisTarih = this.bultenForm.bitisTarihi ? moment(this.bultenForm.bitisTarihi).format('YYYY-MM-DD hh:mm:ss') : ''

                        // bultenID, kategoriID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, baslamaTarihi, bitisTarihi, durum, seoBaslik, seoIcerik
                        bultenService.bultenGuncelleme(this.bultenForm.bultenID, this.bultenForm.kategori, this.bultenForm.baslik, this.bultenForm.altBaslik, this.bultenForm.icerik, sunucudanEklenenResimler, this.bultenForm.resim.length > 0 ? this.bultenForm.resim : "", resimUzunluk, this.bultenForm.video, this.bultenForm.resimKonum, this.bultenForm.menuKonum, this.bultenForm.galeriKonum, baslamaTarih, bitisTarih, this.bultenForm.durum, this.bultenForm.seoBaslik, this.bultenForm.seoIcerik).then((response) => {
                            if(response.status == 200){
                                EventBus.$emit("bultenListe", true)
                                this.resetForm("bultenForm")
                                this.sayfaKapat('bultenduzenle')
                            }
                            localStorage.setItem("userDataDemirag", response.token)
                            notification.Status("success", this, response.msg);
                            this.loading = false
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                notification.Status(503, this)
                            }
                            this.loading = false;
                        })
                    }catch(e){
                        this.loading = false;
                    }
                } 
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();

                this.sayfaKapat("bultenduzenle")
            },

        }
    }
</script>